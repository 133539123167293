import React from "react"
import AddressList from "./address-list"
import Img from "gatsby-image"
import tw, { styled, css } from "twin.macro"
import SocialMedia from "./social-media"

const ADDRESS_WIDTH = 800

const Wrapper = tw.div`flex w-full bg-white`

const Banner = styled.div(() => [
  tw`relative flex items-center justify-center w-full`,
  css`
    width: calc(100% - ${ADDRESS_WIDTH}px);
    .gatsby-image-wrapper {
      ${tw`w-full absolute!`}
    }

    .bg {
      ${tw`top-0 left-0 object-cover h-full`}
    }

    .fg {
      ${tw`absolute!`}
      width: 675px;
      margin-left: -15%;
      top: -6rem;
    }

    @media (max-width: 1440px) {
      .fg {
        top: inherit;
        width: 85%;
      }
    }

    @media (max-width: 1280px) {
      .fg {
        ${tw`hidden`}
      }
    }

    @media (max-width: 1024px) {
      ${tw`hidden`}
    }
  `,
])

const Addresses = styled.div`
  ${tw`flex flex-col items-center justify-center py-12 ml-0`}
  width: ${ADDRESS_WIDTH}px;
  @media (max-width: 1024px) {
    ${tw`w-full`}
  }
`

const AddressesBanner = ({ addresses, bg, fg, socials }) => (
  <Wrapper>
    <Banner>
      <Img
        className="bg"
        fluid={bg.localFile.childImageSharp.fluid}
        alt={bg.altText}
      />
      <Img
        className="fg"
        fluid={fg.localFile.childImageSharp.fluid}
        alt={fg.altText}
      />
    </Banner>
    <Addresses>
      <AddressList items={addresses} />
      <SocialMedia socials={socials} />
    </Addresses>
  </Wrapper>
)

export default AddressesBanner
