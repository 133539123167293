import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/inner"
import SEO from "../components/seo"
import Hero from "../components/modules/blog-hero"
import PostList from "../components/modules/post-list"

export default ({ data, pageContext }) => {
  const page = data.wpPage
  const { edges: posts } = data.allWpPost
  const blogSettings = data.wp.blogSettings
  const { humanPageNumber: pageNum } = pageContext
  const robots = {
    name: `robots`,
    content: pageNum === 1 ? "index, follow" : "noindex, follow",
  }
  return (
    <Layout>
      <SEO
        title={page.seo.title}
        description={page.seo.metaDesc}
        meta={[robots]}
        schema={page.seo.schema?.raw}
      />
      <Hero
        banner={
          data.wp.blogSettings.featuredPost.featuredThumbnail.localFile
            .childImageSharp.fluid
        }
        post={blogSettings.featuredPost.featuredPost}
      />
      <PostList pageContext={pageContext} posts={posts} />
    </Layout>
  )
}

export const query = graphql`
  fragment PostList on WpPost {
    excerpt
    dateGmt(formatString: "M/DD/YY")
    title
    uri
    categories {
      nodes {
        name
        link
      }
    }
  }
  query IndexQuery($limit: Int!, $skip: Int!) {
    wpPage(isPostsPage: { eq: true }) {
      seo {
        ...SEO
      }
    }
    allWpPost(
      sort: { fields: dateGmt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostList
        }
      }
    }
    wp {
      blogSettings {
        featuredPost {
          featuredThumbnail {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          featuredPost {
            ...PostList
          }
        }
      }
    }
  }
`
