import React from "react"
import tw, { styled, css } from "twin.macro"
import { Link } from "gatsby"

const Wrapper = styled.li(() => [
  tw`font-mont relative flex items-start justify-start w-full py-16 overflow-y-hidden text-sm font-bold`,
  css`
    &:not(:last-child):after {
      ${tw`bg-marrone-lightgray absolute bottom-0 left-0 w-full`}
      height: 1px;
      content: "";
    }

    @media (max-width: 1280px) {
      ${tw`flex-col`}
    }
  `,
])
const Time = tw.time`text-marrone-primary mr-6 mt-2`
const Title = styled.p(() => [
  tw`relative overflow-x-hidden overflow-y-visible! transition-all duration-500 ease-in-out font-mont font-bold text-4xl hover:text-marrone-primary`,
  css`
    line-height: 1.2;
    width: 600px;

    &:after {
      ${tw`bg-marrone-primary absolute bottom-0 left-0 w-full transition-all duration-500 ease-in-out`}
      height: 2px;
      content: "";
      transform: translateX(-105%);
    }

    &:hover:after {
      transform: none;
    }

    @media (max-width: 1280px) {
      ${tw`w-full mt-4`}
    }

    @media (max-width: 640px) {
      ${tw`text-3xl`}
    }
  `,
])
const Content = styled.article(() => [
  tw`flex flex-col items-start justify-start ml-8`,
  css`
    max-width: 600px;

    @media (max-width: 1280px) {
      ${tw`w-full mt-8 ml-0`}
      max-width: 100%;
    }
  `,
])
const Excerpt = tw.div`leading-loose font-normal text-base`

export default ({ post }) => {
  return (
    <Wrapper>
      <Time>{post.dateGmt}</Time>
      <Link to={post.uri}>
        <Title>{post.title}</Title>
      </Link>
      <Content>
        <Excerpt
          dangerouslySetInnerHTML={{
            __html: post.excerpt.replace(" [&hellip;]", "&hellip;"),
          }}
        />
      </Content>
    </Wrapper>
  )
}
