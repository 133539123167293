import React from "react"
import tw, { styled, css } from "twin.macro"
import Img from "gatsby-image"
import Heading from "./heading"
import { Link } from "gatsby"

const Wrapper = styled.div(() => [
  tw`relative py-24 overflow-hidden w-full flex flex-col items-start justify-center font-mont`,
  css`
    @media (min-width: 1281px) {
      margin-top: 6.5rem;
    }

    @media (max-width: 1280px) and (min-width: 641px) {
      margin-top: 105px;
    }

    .gatsby-image-wrapper {
      ${tw`transition-all duration-500 ease-in-out absolute top-0 left-0 w-full h-full object-cover`}
    }

    .heading {
      ${tw`relative overflow-hidden text-5xl`}
      &:after {
        ${tw`transition-all duration-500 ease-in-out absolute bottom-0 left-0 w-full bg-marrone-primary`}
        height: 2px;
        transform: translateX(-100%);
        content: "";
      }
    }

    &:hover {
      .gatsby-image-wrapper {
        transform: scale(1.05);
        filter: grayscale(100%);
      }

      .heading {
        &:after {
          transform: none;
        }
      }
    }
  `,
])

const Content = styled.div(() => [
  tw`relative pl-24 pr-6 text-white text-base font-bold`,
  css`
    @media (max-width: 1024px) {
      ${tw`px-6`}
    }
  `,
])
const Title = tw.p`text-marrone-lightgray mb-8`
const Info = styled.div(() => [
  css`
    @media (max-width: 1024px) {
      ${tw`flex flex-col`}
    }

    @media (max-width: 640px) {
      .heading {
        ${tw`text-3xl`}
      }
    }
  `,
])
const Date = tw.time`text-marrone-primary mr-12`
const Article = styled.article(() => [
  tw`flex flex-col items-start justify-start`,
  css`
    max-width: 800px;
  `,
])
const Excerpt = tw.p`leading-loose font-normal`

export default ({ banner, post }) => {
  return (
    <Link to={post.uri}>
      <Wrapper>
        <div tw="absolute w-full h-full">
          <Img fluid={banner} />
        </div>
        <Content>
          <Title>Featured Blog</Title>
          <Info>
            <Date>{post.dateGmt}</Date>
            <Article>
              <Heading>{post.title}</Heading>
              <Excerpt
                dangerouslySetInnerHTML={{
                  __html: post.excerpt.replace(" [&hellip;]", "&hellip;"),
                }}
              />
            </Article>
          </Info>
        </Content>
      </Wrapper>
    </Link>
  )
}
