import React from "react"
import tw, { styled, css } from "twin.macro"
import { Link } from "gatsby"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import {
//   faChevronLeft,
//   faChevronRight,
// } from "@fortawesome/pro-regular-svg-icons"

const Wrapper = styled.div(() => [
  tw`flex items-end justify-between w-full font-bold font-mont`,
  css`
    max-width: 1400px;

    a.disabled {
      ${tw`opacity-0 pointer-events-none`}
    }

    a {
      ${tw`transition-all duration-500 ease-in-out`}

      &:hover {
        ${tw`text-marrone-primary`}
      }
    }

    @media (max-width: 640px) {
      ${tw`text-sm`}
    }
  `,
])

const Number = tw.p`text-marrone-primary mr-4 mb-6`

export default ({ pageContext }) => {
  const {
    nextPagePath: next,
    previousPagePath: prev,
    humanPageNumber: pageNum,
    numberOfPages,
  } = pageContext
  return (
    <Wrapper className="pagination">
      <div tw="flex flex-col">
        <Number>{`Page ${pageNum}/${numberOfPages}`}</Number>
        <Link to={prev && `${prev}/`} className={!prev && "disabled"}>
          {/* <FontAwesomeIcon tw="mr-2" icon={faChevronLeft} /> Previous Page */}
          Previous Page
        </Link>
      </div>
      <Link to={next && `${next}/`} className={!next && "disabled"}>
        {/* Next Page <FontAwesomeIcon tw="ml-2" icon={faChevronRight} /> */}
        Next Page
      </Link>
    </Wrapper>
  )
}
