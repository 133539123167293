import React from "react"
import "../../styles/footer-inner.css"
import { graphql, StaticQuery } from "gatsby"
import AddressesBanner from "../modules/addresses-banner"
import Copyright from "../modules/footer-copyright"

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          socialMedia {
            socialMedia {
              account {
                url
                platform
              }
            }
          }
          contactInfo {
            contactInfo {
              firmAddresses {
                addressL1
                addressL2
                addressMapsUrl
                faxNumber
                phoneNumber
                title
              }
            }
          }
          innerPageSettings {
            innerFooters {
              footerBg {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              footerFg {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const contactInfo = data.wp.contactInfo.contactInfo
      const footerSettings = data.wp.innerPageSettings.innerFooters
      const socials = data.wp.socialMedia.socialMedia.account

      return (
        <footer className="footer">
          <AddressesBanner
            bg={footerSettings.footerBg}
            fg={footerSettings.footerFg}
            addresses={contactInfo.firmAddresses}
            socials={socials}
          />
          <div className="footer__copy">
            <Copyright />
          </div>
        </footer>
      )
    }}
  />
)

export default Footer
