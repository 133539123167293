import React from "react"
import tw, { styled, css } from "twin.macro"
import PostExcerpt from "./post-excerpt"
import Pagination from "./pagination"

const Wrapper = styled.div(() => [
  tw`flex w-full px-12 flex-col py-24 ml-0 items-center justify-center`,
  css`
    @media (max-width: 1280px) {
      ${tw`pb-0`}
    }
    @media (max-width: 640px) {
      ${tw`px-4 pt-6`}
    }
  `,
])
const List = styled.ul(() => [
  tw`flex flex-col items-start justify-start`,
  css`
    max-width: 1400px;
  `,
])

export default ({ posts, pageContext }) => (
  <Wrapper id="posts">
    <Pagination pageContext={pageContext} />
    <List>
      {posts.map(({ node: post }) => (
        <PostExcerpt post={post} />
      ))}
    </List>
  </Wrapper>
)
